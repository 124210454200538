@use 'common-colors' as common;

:root {
	--color-font-notifications-badge: var(--color-text-white);

	--color-border-notifications-badge: var(--color-text-white);

	@include common.setContentWhenDarkTheme {
		--color-font-notifications-badge: rgb(var(--color-text-800-rgb));
		--color-border-notifications-badge: rgb(var(--color-text-800-rgb));
	}
}
