:root {
	color-scheme: light dark;

	--z-index-max: 2147483647;
}

html,
body {
	position: fixed;

	width: 100%;
	height: 100%;

	margin: 0;

	overflow: hidden;

	touch-action: none !important;
	-webkit-overflow-scrolling: touch;
}

.uppercase {
	text-transform: uppercase;
}

.dis,
.display {
	&-b,
	&-block {
		display: block;
	}

	&-n,
	&-none {
		display: none;
	}
}

.block {
	display: block;

	&-inline {
		display: inline-block;
	}
}

.inline {
	display: inline-block;
}

.ov,
.overflow {
	&-h,
	&-hidden {
		overflow: hidden;
	}

	&-a,
	&-auto {
		overflow: auto;
	}

	&-v,
	&-visible {
		overflow: visible;
	}

	&-horizontal {
		overflow-y: hidden;
		overflow-x: auto;
		scroll-behavior: smooth;
	}

	&-vertical {
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.scroll-hidden {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.without-focus,
.wo-focus {
	outline: none;
}

.unclickable {
	pointer-events: none;
}

.z-index {
	@for $i from 0 through 10 {
		&-#{$i} {
			z-index: $i;
		}
	}
}

.disable-text-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pointer-events,
.pe {
	&-none {
		pointer-events: none;
	}

	&-all {
		pointer-events: all;
	}
}

.popup {
	border-radius: calc(2 * var(--spacing));
	box-shadow: var(--shadow-2);
	border: 1px solid var(--color-line-230);
	box-sizing: border-box;
}

// this is legacy, do not use if possible.

.list-item-separator,
.li-separator {
	position: relative;
	&::after {
		--space: calc(1rem + 8px + var(--fs-icon) + 8px);

		content: '';
		position: absolute;
		bottom: 0;
		left: var(--space);
		width: calc(100% - var(--space));
		max-width: 100%;
		height: 1px;
		background: var(--color-line-250);
	}
}

.list-item-separator-no-icon,
.li-separator-no-icon {
	position: relative;
	&::after {
		--space: calc(2 * var(--spacing));

		content: '';
		position: absolute;
		bottom: 0;
		left: var(--space);
		width: calc(100% - var(--space));
		max-width: 100%;
		height: 1px;
		background: var(--color-line-250);
	}
}
