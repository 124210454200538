select {
	width: 100%;

	padding: 0.5em 1em;

	background-color: var(--color-background-100, transparent);
	border-radius: 0.5em;

	border: 1px solid var(--color-line-230);
	caret-color: transparent;

	font-weight: var(--fw-regular, 400);

	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	outline: 0;
	font-size: inherit;

	&:not([disabled]) {
		cursor: pointer;
		color: var(--color-text-800);
	}
}
