markdown {
	--spacing: 8px;

	line-height: 1.5;

	h1 {
		font-size: 26px;
		line-height: 30px;
		font-weight: 600;
	}

	h2 {
		font-size: 22px;
		line-height: 26px;
		font-weight: 600;
	}

	h3 {
		font-size: 22px;
		line-height: 26px;
		font-weight: 400;
	}

	h4 {
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
	}

	h5 {
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
	}

	h6 {
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
	}

	ul,
	ol {
		margin: 0;
		padding: 0;

		> ul,
		> li > ul,
		> ol,
		> li > ol {
			padding-left: calc(2 * var(--spacing));
		}

		list-style-position: inside;

		white-space: nowrap;

		> li {
			&::marker {
				color: rgb(var(--color-text-400-rgb))
			}

			margin: calc(1.25 * var(--spacing)) 0;
			white-space: pre-line;

			> p {
				display: contents;
				vertical-align: text-top;
			}
		}
	}

	table {
		border-collapse: collapse;
	}

	th {
		background-color: rgb(var(--color-line-230-rgb));
		color: rgb(var(--color-text-800-rgb));
		font-weight: var(--fw-bold);

		&:first-of-type {
			border-top-left-radius: 8px;
		}

		&:last-of-type {
			border-top-right-radius: 8px;
		}
	}

	tr {
		&:last-child {
			td {
				&:first-of-type {
					border-bottom-left-radius: 8px;
				}

				&:last-of-type {
					border-bottom-right-radius: 8px;
				}
			}
		}
	}

	th,
	td {
		box-shadow: 0 0 0 1px rgb(var(--color-line-230-rgb));
		padding: var(--spacing);
		box-sizing: border-box;
	}

	blockquote {
		white-space: wrap;

		margin: 0;
		padding: 0;
		padding-left: calc(2 * var(--spacing));
		box-sizing: border-box;

		color: rgb(var(--color-text-800-rgb));

		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;

			width: var(--spacing);
			height: 100%;

			border-radius: 2px;

			background-color: rgba(var(--color-primary-rgb), 0.2);
		}

		> p {
			white-space: pre-line;
		}
	}

	code[class*='language-'],
	pre[class*='language-'] {
		background-color: rgb(var(--color-code-bg-rgb)); // this is the dark color `bg-170`
		color: rgb(var(--color-text-white-rgb));

		&.line-numbers {
			.line-numbers-rows {
				border-color: rgb(var(--color-line-230-rgb));
				> span::before {
					color: rgb(var(--color-text-400-rgb));
				}
			}
		}
	}

	p {
		white-space: pre-line;
	}

	a {
		color: rgb(var(--color-primary-rgb));

		&.source-message-chip {
			display: inline-flex;
			align-items: center;
			justify-content: center;

			min-width: calc(2 * var(--spacing));
			height: calc(2 * var(--spacing));
			border-radius: var(--spacing);
			margin: 0px 2px;

			font-size: var(--fs-s);
			color: rgb(var(--color-text-100-rgb));
			background-color: rgb(var(--color-line-330-rgb));

			cursor: pointer;

			&.expanded {
				padding: 0px 4px;
			}

			&:hover {
				background-color: rgb(var(--color-primary-rgb));
			}

			&.hidden {
				display: none;
			}
		}
	}
}
