// h1, h2, etc tags are in `html-tags/header.scss`.

.reach-section-header {
	width: 100%;

	background-color: var(--color-bg-modal-search);

	&.new-colors {
		background-color: var(--color-bg-370);

		&-290 {
			background-color: var(--color-bg-290);
		}
	}

	&.transparent,
	&.alt-transparent {
		background-color: transparent;
	}

	padding: 24px 16px 12px 16px;
	box-sizing: border-box;

	color: var(--color-text-400);
	font-size: var(--fs-xs);
	font-weight: var(--fw-bold);

	line-height: 1em;
	letter-spacing: 2px;

	text-transform: uppercase;

	&-alt {
		width: 100%;

		background-color: rgb(var(--color-bg-180-rgb));

		padding: 24px 16px 12px 16px;
		box-sizing: border-box;

		color: var(--color-text-400);
		font-size: var(--fs-s);
		font-weight: var(--fw-regular);

		line-height: var(--fs-m);
	}
}

@media screen and (min-width: 720px) {
	.reach-section-header {
		font-size: var(--fs-xxs);
	}
	.reach-section-header.font-size-mobile {
		font-size: var(--fs-xs);
	}
}
