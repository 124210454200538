@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';
@use 'common-colors' as common;
@use 'theme-colors' as theme;
@use 'importance-colors' as importance;

$map: (
	'light': (
		'white': common.$color-white-rgb,
		'100': common.$color-white-rgb,
		'200': (
			195,
			194,
			198,
		),
		// #C3C2C6
		'400':
			(
				139,
				146,
				162,
			),
		// #8B92A2
		'500':
			(
				98,
				102,
				113,
			),
		// #626671
		'800':
			(
				27,
				42,
				74,
			),
		// #1B2A4A
	),
	'dark': (
		'white': common.$color-white-rgb,
		'100': common.$color-white-rgb,
		'200': (
			86,
			86,
			91,
		),
		// # 56565b
		'400':
			(
				139,
				146,
				162,
			),
		// #8B92A2
		'500':
			(
				175,
				182,
				198,
			),
		// #AFB6C6
		'800':
			(
				227,
				227,
				227,
			),
		// #E3E3E3
	),
);

$keys: map.keys(map.get($map, 'light'));

@function cssVarNameGenerator($name) {
	@return '--color-text-#{$name}-rgb';
}

$reach-code-colors: (
	'html': (
		// #C2FF00
		'tag': '194, 255, 0',

		// #51D2F3
		'attribute-name': '81, 210, 243',

		// #ABDCFF
		'attribute-value': '171, 220, 255',
	),
);

:root {
	@include common.setCssVariablesFromLightAndDarkMap(
		$map,
		meta.get-function('cssVarNameGenerator')
	);

	@each $lang, $lang-map in $reach-code-colors {
		@each $name, $rgb in $lang-map {
			--color-code-#{$lang}-#{$name}-rgb: #{$rgb};
			--color-code-#{$lang}-#{$name}: rgb(var(--color-code-#{$lang}-#{$name}-rgb));
		}
	}
}

body {
	color: rgb(var(string.unquote(cssVarNameGenerator(800))));
}

@mixin setFontColor($name, $cssVarName, $includeOtherProperties: false) {
	&-#{$name} {
		@at-root :root & {
			color: rgb(var(#{$cssVarName}));

			@include common.addAlphaValues($cssVarName, color);

			@if $includeOtherProperties {
				&-as-bg {
					background-color: rgb(var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, background-color);
				}

				&-as-border,
				&-as-line {
					border-color: rgb(var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, border-color);
				}
			}
		}

		@include common.addHoverClasses(rgb(var(#{$cssVarName})), color);
	}
}

.text,
.fc {
	@each $lang, $lang-map in $reach-code-colors {
		@each $name, $rgb in $lang-map {
			&-code-#{$lang}-#{$name} {
				color: var(--color-code-#{$lang}-#{$name});
			}
		}
	}

	@each $name in $keys {
		@include setFontColor(
			$name: $name,
			$cssVarName: cssVarNameGenerator($name),
			$includeOtherProperties: true
		);
	}

	&,
	&-theme {
		@each $name in theme.$keys {
			@include setFontColor(
				$name: $name,
				$cssVarName: theme.cssVarNameGenerator($name),
				$includeOtherProperties: false
			);
		}
	}

	// &-importance,
	// &-imp {
	// 	@each $name in importance.$keys {
	// 		@include setFontColor(
	// 			$name,
	// 			$cssVarName: importance.cssVarNameGenerator($name),
	// 			$includeOtherProperties: false
	// 		);
	// 	}
	// }

	&-placeholder::placeholder {
		color: var(--color-text-400);
		opacity: 1;
	}
}
