body {
	--hot-toast-bg: rgb(var(--color-bg-280-rgb));
	--hot-toast-shadow: var(--shadow-2);
	--hot-toast-color: var(--color-text-800);
}

.hot-toast-error-icon {
	--error-primary: var(--color-warn) !important;
	--error-secondary: var(--hot-toast-bg) !important;
}

hot-toast-container {
	& > div {
		padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
			var(--safe-area-inset-bottom) var(--safe-area-inset-left) !important;
		box-sizing: border-box;
	}
}
