.options-menu {
	background-color: var(--color-bg-270);
	border-radius: var(--spacing);
	overflow: hidden;

	padding: var(--spacing);

	width: 320px;

	box-shadow: var(--shadow-menu);

	&.small {
		border-radius: calc(1.5 * var(--spacing));
		width: 200px;
	}

	&.alt {
		background-color: var(--color-bg-180);
	}

	> .section-header:first-of-type {
		padding-top: calc(2 * var(--spacing));
	}

	&:has(.options-footer) {
		padding-bottom: 0;
	}

	> .options-footer {
		width: calc(100% + (2 * var(--spacing)));
		margin-left: calc(-1 * var(--spacing));
	}
}

.options-list {
	border-radius: var(--spacing);
	overflow: hidden;

	.option {
		border-radius: 0px;
		background-color: var(--color-bg-190);

		padding: calc(1.5 * var(--spacing)) calc(2 * var(--spacing));

		&:hover {
			background-color: var(--color-bg-270);
		}
	}
}
