// .reach-section-header is in `base/headers.scss`.

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0px;
}
