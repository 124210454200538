@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';
@use 'common-colors' as common;
@use 'theme-colors' as theme;
@use 'importance-colors' as importance;

$aux-map: (
	'light': (
		'1': (
			255,
			255,
			255,
		),
		// #FFFFFF
		'2':
			(
				244,
				245,
				247,
			),
		// #F4F5F7
		'3':
			(
				242,
				241,
				246,
			),
		// #F2F1F6
		'4':
			(
				230,
				230,
				235,
			),
		// #E6E6EB
		'5':
			(
				208,
				212,
				221,
			),
		// #D0D4DD
	),
	'dark': (
		'6': (
			62,
			62,
			68,
		),
		// #3E3E44
		'7':
			(
				44,
				44,
				50,
			),
		// #2C2C32
		'8':
			(
				28,
				28,
				34,
			),
		// #1C1C22
		'9':
			(
				10,
				10,
				16,
			),
		// #0a0a10
	),
);

/* 
	This is a helper map to generate all the permutations that we have
	for the background colors.

	The names of the bg colors are: <LIGHT_VALUE><DARK_VALUE>0.
*/
$map: common.getAllLightAndDarkMapPermutations($aux-map);

$keys: map.keys(map.get($map, 'light'));

$misc-map: (
	'light': (
		'standard-hover': (
			27,
			42,
			74,
			0.04,
		),
	),
	'dark': (
		'standard-hover': (
			227,
			227,
			227,
			0.04,
		),
	),
);

$misc-keys: map.keys(map.get($misc-map, 'light'));

@function cssVarNameGenerator($name) {
	@return '--color-bg-#{$name}-rgb';
}

// MISC
$reach-code-bg: map.get($aux-map, 'dark', '7');
$reach-code-text: '255, 255, 255'; // #FFFFFF

:root {
	--code-code-bg-rgb: #{$reach-code-bg};
	--code-code-bg: rgb(var(--code-code-bg-rgb));

	--code-code-text-rgb: #{$reach-code-text};
	--code-code-text: rgb(var(--code-code-text-rgb));

	--color-bg-menu-backdrop-opacity: 0.2;

	@include common.setContentWhenDarkTheme {
		--color-bg-menu-backdrop-opacity: 0.5;
	}

	@include common.setCssVariablesFromLightAndDarkMap(
		$map,
		meta.get-function('cssVarNameGenerator')
	);

	@include common.setCssVariablesFromLightAndDarkMap(
		$misc-map,
		meta.get-function('cssVarNameGenerator')
	);
}

@function rgbOrRgba($useRgba, $value) {
	@if $useRgba {
		@return rgba($value);
	} @else {
		@return rgb($value);
	}
}

@mixin setBackgroundColor(
	$name,
	$cssVarName,
	$includeOtherProperties: false,
	$varIncludesAlpha: false
) {
	&-#{$name} {
		// this is to add more specificity
		@at-root :root & {
			background-color: rgbOrRgba($varIncludesAlpha, var(#{$cssVarName}));

			@if $varIncludesAlpha == false {
				@include common.addAlphaValues($cssVarName, background-color);
			}

			@if $includeOtherProperties {
				&-as-fc {
					color: rgbOrRgba($varIncludesAlpha, var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, color);
				}

				&-as-border,
				&-as-line {
					border-color: rgbOrRgba($varIncludesAlpha, var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, border-color);
				}
			}
		}

		@include common.addHoverClasses(
			rgbOrRgba($varIncludesAlpha, var(#{$cssVarName})),
			background-color
		);
	}
}

.bg {
	&-backdrop {
		background-color: rgba(0, 0, 0, var(--color-bg-menu-backdrop-opacity));
		@include common.addHoverClasses(
			rgba(0, 0, 0, var(--color-bg-menu-backdrop-opacity)),
			background-color
		);
	}

	&-current-color {
		background-color: currentColor;
	}

	&-white {
		background-color: rgb(list.join(common.$color-white-rgb, (), 'space'));
	}

	@each $name in $keys {
		@include setBackgroundColor(
			$name: $name,
			$cssVarName: cssVarNameGenerator($name),
			$includeOtherProperties: true,
			$varIncludesAlpha: false
		);
	}

	@each $name in $misc-keys {
		@include setBackgroundColor(
			$name: $name,
			$cssVarName: cssVarNameGenerator($name),
			$includeOtherProperties: false,
			$varIncludesAlpha: true
		);

		&-#{$name}-over {
			@each $overName in $keys {
				&-#{$overName}-on-hover:hover,
				&-#{$overName} {
					background: linear-gradient(
							0deg,
							rgba(var(string.unquote(cssVarNameGenerator($name)))),
							rgba(var(string.unquote(cssVarNameGenerator($name))))
						),
						rgb(var(string.unquote(cssVarNameGenerator($overName))));
				}
			}
		}
	}

	&,
	&-theme {
		@each $name in theme.$keys {
			@include setBackgroundColor(
				$name: $name,
				$cssVarName: theme.cssVarNameGenerator($name),
				$includeOtherProperties: false,
				$varIncludesAlpha: false
			);
		}
	}

	&-importance,
	&-imp {
		@each $name in importance.$keys {
			@include setBackgroundColor(
				$name: $name,
				$cssVarName: importance.cssVarNameGenerator($name),
				$includeOtherProperties: false,
				$varIncludesAlpha: false
			);
		}
	}
}

// EXTRA CLASSES (not included everywhere for bundle size)
:root .bg {
	&-190 {
		$rgb: var(--color-bg-190-rgb);

		&-blur {
			&-50-100 {
				background-color: rgba($rgb, 1);

				@supports (backdrop-filter: blur(8px)) or (--webkit-backdrop-filter: blur(8px)) {
					$opaque: rgba($rgb, 0.5);
					$transparent: rgba($rgb, 1);

					$gradient: $opaque, $transparent;
					background: linear-gradient(to bottom, $gradient);
					backdrop-filter: blur(8px);
					--webkit-backdrop-filter: blur(8px);
				}
			}

			&-100-50 {
				background-color: rgba($rgb, 1);

				@supports (backdrop-filter: blur(8px)) or (--webkit-backdrop-filter: blur(8px)) {
					$opaque: rgba($rgb, 1);
					$transparent: rgba($rgb, 0.5);

					$gradient: $opaque, $transparent;
					background: linear-gradient(to bottom, $gradient);
					backdrop-filter: blur(8px);
					--webkit-backdrop-filter: blur(8px);
				}
			}
		}
	}
}
