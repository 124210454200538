@font-face {
    font-family: "node-subtype-icomoon";
    src: url("./node-subtype-icomoon.eot?68742ff0014961db7908053bd0aec014#iefix") format("embedded-opentype"),
url("./node-subtype-icomoon.woff2?68742ff0014961db7908053bd0aec014") format("woff2"),
url("./node-subtype-icomoon.woff?68742ff0014961db7908053bd0aec014") format("woff");
}

[class^="ns-icon-"]:before, [class*=" ns-icon-"]:before {
    font-family: node-subtype-icomoon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ns-icon-workspace:before {
    content: "\f101";
}
.ns-icon-website:before {
    content: "\f102";
}
.ns-icon-warning-white:before {
    content: "\f103";
}
.ns-icon-volume:before {
    content: "\f104";
}
.ns-icon-update-arrows:before {
    content: "\f105";
}
.ns-icon-translations:before {
    content: "\f106";
}
.ns-icon-tick-square:before {
    content: "\f107";
}
.ns-icon-terrain:before {
    content: "\f108";
}
.ns-icon-teams-group:before {
    content: "\f109";
}
.ns-icon-tag:before {
    content: "\f10a";
}
.ns-icon-sun-light:before {
    content: "\f10b";
}
.ns-icon-star:before {
    content: "\f10c";
}
.ns-icon-smartphone:before {
    content: "\f10d";
}
.ns-icon-search:before {
    content: "\f10e";
}
.ns-icon-reach:before {
    content: "\f10f";
}
.ns-icon-puzzle-outline:before {
    content: "\f110";
}
.ns-icon-preview:before {
    content: "\f111";
}
.ns-icon-play:before {
    content: "\f112";
}
.ns-icon-pin-unclicked:before {
    content: "\f113";
}
.ns-icon-palette:before {
    content: "\f114";
}
.ns-icon-notification-sound:before {
    content: "\f115";
}
.ns-icon-note:before {
    content: "\f116";
}
.ns-icon-moon-dark:before {
    content: "\f117";
}
.ns-icon-menu-book:before {
    content: "\f118";
}
.ns-icon-mention-at:before {
    content: "\f119";
}
.ns-icon-memory-archive-folder:before {
    content: "\f11a";
}
.ns-icon-magic-wand:before {
    content: "\f11b";
}
.ns-icon-lock:before {
    content: "\f11c";
}
.ns-icon-location:before {
    content: "\f11d";
}
.ns-icon-loading:before {
    content: "\f11e";
}
.ns-icon-link:before {
    content: "\f11f";
}
.ns-icon-light:before {
    content: "\f120";
}
.ns-icon-label-tag:before {
    content: "\f121";
}
.ns-icon-interests:before {
    content: "\f122";
}
.ns-icon-image:before {
    content: "\f123";
}
.ns-icon-home:before {
    content: "\f124";
}
.ns-icon-heart:before {
    content: "\f125";
}
.ns-icon-geolocation:before {
    content: "\f126";
}
.ns-icon-flag:before {
    content: "\f127";
}
.ns-icon-emoji-happy:before {
    content: "\f128";
}
.ns-icon-email:before {
    content: "\f129";
}
.ns-icon-document:before {
    content: "\f12a";
}
.ns-icon-diamond-normal:before {
    content: "\f12b";
}
.ns-icon-day-calendar-date:before {
    content: "\f12c";
}
.ns-icon-date:before {
    content: "\f12d";
}
.ns-icon-dashboard:before {
    content: "\f12e";
}
.ns-icon-currency:before {
    content: "\f12f";
}
.ns-icon-crown:before {
    content: "\f130";
}
.ns-icon-contact:before {
    content: "\f131";
}
.ns-icon-company:before {
    content: "\f132";
}
.ns-icon-clock:before {
    content: "\f133";
}
.ns-icon-chat:before {
    content: "\f134";
}
.ns-icon-camera:before {
    content: "\f135";
}
.ns-icon-bolt:before {
    content: "\f136";
}
