:root {
	--font-family-base: 'Helvetica Neue', 'Roboto', Arial, sans-serif;

	--font-family-serif: Georgia, Times New Roman, serif;
	--font-family-monospace: Monaco, Courier New, monospace;
}

html {
	font-family: 'Helvetica Neue', 'Roboto', Arial, sans-serif;
	font-family: var(--font-family-base);
}

input,
textarea,
button {
	font-family: inherit;
}

.font-family {
	&-default,
	&-base {
		font-family: var(--font-family-base);
	}

	&-serif {
		font-family: var(--font-family-serif);
	}

	&-monospace {
		font-family: var(--font-family-monospace);
	}
}
