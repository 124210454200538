@use '_spacing-directions' as directions;

/*
    `p` is for padding.
    `p-x` is for padding with x times --spacing.
    `p-xh` is for padding with x.5 times --spacing (`h` is for half`, but you can use `.5` also).
    `p-x-y` is for padding-Y with x times --spacing.
    Where y:
        - can be 't', 'r', 'b' or 'l' (top, right, bottom, left).
        - can be combined with other "y's", but following a clock-wise order starting at top (t -> r -> b -> l).
            e.g.: `p-1-trl` means "padding 1 at top, right and left".
            e.g.: `p-1-ltr` is invalid, because it is not clock-wise starting at top.
        - if not specified (e.g.: `p-2`, it assumes all sides).
*/
:root .p {
	&-safe {
		padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
			var(--safe-area-inset-bottom) var(--safe-area-inset-left);
	}

	@each $direction, $permutations in directions.$all-sides {
		@each $permutation in $permutations {
			&-safe-#{$permutation} {
				padding-#{$direction}: var(--safe-area-inset-#{$direction});
			}
		}
	}

	@for $i from 0 through 5 {
		@each $division, $division-value in directions.$spacing-multiplications {
			@each $direction, $permutations in directions.$all-sides {
				&-#{$i}#{$division} {
					padding-#{$direction}: calc((#{$i} + #{$division-value}) * var(--spacing));
				}

				@each $permutation in $permutations {
					&-#{$i}#{$division}-#{$permutation} {
						padding-#{$direction}: calc((#{$i} + #{$division-value}) * var(--spacing));
					}
				}
			}
		}
	}

	@for $i from 6 through 10 {
		@each $direction, $permutations in directions.$all-sides {
			&-#{$i} {
				padding-#{$direction}: calc(#{$i} * var(--spacing));
			}

			@each $permutation in $permutations {
				&-#{$i}-#{$permutation} {
					padding-#{$direction}: calc(#{$i} * var(--spacing));
				}
			}
		}
	}

	// adding just the bits that we need
	&-8h {
		@each $direction, $permutations in directions.$all-sides {
			@each $permutation in $permutations {
				&-#{$permutation} {
					padding-#{$direction}: calc(8.5 * var(--spacing));
				}
			}
		}
	}
}
