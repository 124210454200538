input,
textarea,
[contenteditable] {
	caret-color: var(--color-primary);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

textarea,
input {
	outline: none;
	color: var(--color-text-800);
	font-family: inherit;
	font-size: var(--fs-m);

	border: none;
	box-sizing: border-box;

	background-color: transparent;

	padding: 0px;
	margin: 0px;

	&::placeholder {
		color: var(--color-text-400);
		opacity: 1;
	}
}

.input-with-label-wrapper {
	> input:not(:placeholder-shown) {
		+ .label {
			opacity: 0;
		}
	}

	.label {
		position: absolute;
		top: 50%;
		left: 1rem;
		transform: translateY(-50%);
		opacity: 1;

		pointer-events: none;
	}
}

.icon-input-icon {
	border-radius: 8px;

	padding: 0px 8px;
	box-sizing: border-box;

	display: flex;
	align-items: stretch;
	justify-content: flex-start;

	background-color: rgb(var(--color-bg-180-rgb));

	color: var(--color-text-400);

	> .icon {
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			padding-right: 8px;
		}

		&:last-child {
			padding-left: 8px;
		}
	}

	> input {
		flex: 1;

		padding: 8px 0px;
		box-sizing: border-box;

		color: var(--color-text-800);

		&::placeholder,
		&::ms-input-placeholder {
			color: var(--color-text-400);
			opacity: 1;
		}
	}
}
