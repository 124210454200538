@use '../spacing/_spacing-directions' as directions;
@use '../fonts/font-size.scss' as fonts;

$size-properties: (
	'min-width': 'min-w',
	'width': 'w',
	'max-width': 'max-w',
	'min-height': 'min-h',
	'height': 'h',
	'max-height': 'max-h',
);

$reach-sizes: (
	'menu-xs': '200px',
	'menu-s': '320px',
	'content-s': '720px',
	'content': '800px',
	'content-l': '920px',
);

:root {
	@each $name, $size in $reach-sizes {
		--size-#{$name}: #{$size};
	}
}

@each $property, $alias in $size-properties {
	.#{$alias},
	.#{$property} {
		&-0 {
			#{$property}: 0px;
		}

		@for $percent from 1 through 10 {
			&-#{$percent * 10} {
				#{$property}: calc(#{$percent} * 10%);
			}
		}

		// This generates the width and height based on the `--spacing` variable.
		&-sp {
			@for $i from 0 through 10 {
				@each $division, $division-value in directions.$spacing-multiplications {
					&-#{$i}#{$division} {
						#{$property}: calc((#{$division-value} + #{$i}) * var(--spacing));
					}
				}
			}
		}

		&-px {
			&-1 {
				#{$property}: 1px;
			}
		}

		&-fs {
			@each $name, $size in fonts.$font-sizes-desktop {
				&-#{$name} {
					#{$property}: var(--fs-#{unquote($name)});
				}
			}
		}

		@each $name, $size in $reach-sizes {
			&-#{$name} {
				#{$property}: unquote($size);
			}
		}
	}
}

:root {
	// TODO: discuss with design this sizes.

	--client-size-desktop: 720px;

	--size-max-width-desktop: 760px;
	--small-max-width-desktop: 320px;

	&.screen-mobile {
		.hide-on-mobile {
			display: none;
		}
	}
	&.screen-desktop {
		.width {
			&-client-size-desktop {
				width: var(--client-size-desktop);
			}
		}

		.height {
			&-client-size-desktop {
				height: var(--client-size-desktop);
			}
		}

		.max-width-desktop {
			max-width: var(--size-max-width-desktop);
		}

		.max-height-desktop {
			max-height: var(--size-max-width-desktop);
		}

		.desktop-small-max-width {
			max-width: var(--small-max-width-desktop);
		}
	}
}
