img,
object.as-img {
	&:not(.left-as-is) {
		display: inline-block;

		max-height: 100%;

		color: transparent;

		width: auto;
		height: auto;

		&:not(.keep-size).use-width {
			max-height: unset;
			width: 100%;
			max-width: 100%;
		}

		&:not(.keep-size).use-height {
			height: 100%;
			max-height: 100%;
			max-width: unset;
		}
	}
}

img {
	-webkit-touch-callout: default !important;
}
