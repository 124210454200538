@use '_spacing-directions' as directions;

/*
    `br` is for border-radius.
	
	Follows the same structure as `padding.scss`, but for border-radius.

	The clock-wise starts both at `tr` (for top-right) or at `tl` (for top-left).
*/
:root .br {
	&-circle {
		border-radius: 50%;
	}

	@for $i from 0 through 3 {
		@each $division, $division-value in directions.$spacing-multiplications {
			@each $direction, $permutations in directions.$all-corners {
				&-#{$i}#{$division} {
					border-#{$direction}-radius: calc(
						(#{$i} + #{$division-value}) * var(--spacing)
					);
				}

				@each $permutation in $permutations {
					&-#{$i}#{$division}-#{$permutation} {
						border-#{$direction}-radius: calc(
							(#{$i} + #{$division-value}) * var(--spacing)
						);
					}
				}
			}
		}
	}
}
