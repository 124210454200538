@use 'common-colors' as common;

:root {
	--color-bg-modal-rgb: 255, 255, 255;
	--color-bg-modal-search-rgb: 244, 245, 247;

	--color-bg-modal: rgb(var(--color-bg-modal-rgb));
	--color-bg-modal-search: rgb(var(--color-bg-modal-search-rgb));
	--color-bg-dark-modal: rgb(var(--color-bg-290-rgb));

	@include common.setContentWhenDarkTheme {
		--color-bg-modal-rgb: 27, 28, 31;
		--color-bg-modal-search-rgb: 37, 39, 44;
	}
}

.bg-modal,
.bg-m {
	background-color: var(--color-bg-modal);

	&-search {
		background-color: var(--color-bg-modal-search);
	}
}

.bg-dark-modal,
.bg-d-m {
	background-color: var(--color-bg-dark-modal);
}
