mark,
.search-highlight {
	display: inline-block;
	height: 1em;

	color: var(--color-highlight-text);
	font-weight: var(--fw-bold);

	background-color: var(--color-highlight-background);

	border-radius: 4px;

	--__extra-space-x: 2px;
	--__extra-space-y: 0px;

	padding: var(--__extra-space-y) var(--__extra-space-x);
	margin: calc(-1 * var(--__extra-space-y)) calc(-1 * var(--__extra-space-x));
}
