@use 'common-colors' as common;

:root {
	--color-shadow-rgb: 0, 0, 0;
	--color-shadow-alpha: 0.25;
	--color-shadow: rgba(var(--color-shadow-rgb), var(--color-shadow-alpha));

	--shadow-1: 0px 2px 2px rgba(0, 0, 0, 0.2), 0 0 3px 1px rgba(0, 0, 0, 0.1);

	--shadow-2: 0px 14px 14px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.2),
		0 0 4px 3px rgba(0, 0, 0, 0.1);

	--shadow-menu: 4px 10px 16px 0px rgba(12, 30, 70, 0.08), 2px 6px 12px 0px rgba(17, 42, 96, 0.06),
		0px 2px 6px 0px rgba(13, 34, 80, 0.02), 0px 0px 4px 0px rgba(9, 22, 70, 0.02),
		0px 1px 2px 0px rgba(0, 0, 0, 0.02);

	@include common.setContentWhenDarkTheme {
		--shadow-menu: 4px 10px 16px 0px rgba(0, 0, 0, 0.25), 2px 6px 12px 0px rgba(0, 0, 0, 0.25),
			0px 2px 6px 0px rgba(0, 0, 0, 0.2), 0px 0px 4px 0px rgba(14, 17, 28, 0.15),
			0px 1px 2px 0px rgba(0, 0, 0, 0.15);
	}
}

.shadow {
	&-1,
	&-z1 {
		box-shadow: var(--shadow-1);
	}

	&-2,
	&-z2 {
		box-shadow: var(--shadow-2);
	}

	&-menu {
		box-shadow: var(--shadow-menu);
	}
}
