button {
	border: none;
	background: none;
	color: inherit;

	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;
	font-family: inherit;
	letter-spacing: inherit;
	text-transform: inherit;
	line-height: inherit;

	border-radius: 0;

	outline: none;
	margin: 0;
	padding: 0;

	cursor: pointer;

	&[disabled=''],
	&[disabled='true'] {
		cursor: not-allowed;
	}
}

.cursor {
	&-default {
		cursor: default;
	}

	&-pointer {
		cursor: pointer;
	}
}
