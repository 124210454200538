.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ls,
.l-spacing,
.letter-spacing {
	&-l {
		letter-spacing: 3px;
	}
}

.it,
.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;

	&-on-hover:hover {
		text-decoration: underline;
	}
}

.strike,
.strikethrough,
.strike-through,
.linethrough,
.line-through {
	text-decoration: line-through;
}

.fa,
.ta,
.falign,
.talign,
.text-align {
	&-l,
	&-left {
		text-align: left;
	}

	&-c,
	&-center {
		text-align: center;
	}

	&-r,
	&-right {
		text-align: right;
	}
}

.ws,
.white-space {
	&-pl,
	&-pre-line,
	&-preline {
		white-space: pre-line;
	}

	&-nw,
	&-nowrap,
	&-no-wrap {
		white-space: nowrap;
	}
}

.wb,
.word-break {
	&-break-all,
	&-ba {
		word-break: break-all;
	}

	&-break-word,
	&-bw {
		word-break: break-word;
	}
}
