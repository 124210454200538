cdk-row,
cdk-header-row,
cdk-footer-row {
	padding: calc(1.75 * var(--spacing)) calc(2 * var(--spacing));

	display: flex;
	gap: calc(2 * var(--spacing));
}

cdk-cell,
cdk-header-cell,
cdk-footer-cell {
	flex: 1 1;

    &.double {
        flex-grow: 2;
    }

	overflow: hidden;

	&.fixed {
		flex: 0 0 auto;
	}
}

cdk-header-cell {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	color: var(--color-text-400);
}

cdk-cell {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

cdk-table {
	&.small-spacing {
		cdk-row,
		cdk-header-row,
		cdk-footer-row {
			padding: var(--spacing) calc(2 * var(--spacing));
			gap: calc(2 * var(--spacing));
		}
	}
}
