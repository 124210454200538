@use 'sass:list';
@use 'sass:selector';
@use 'sass:string';

/*
	This is how it is structured:
		- key: Name of the class (e.g. `visible` -> `visible-on-hover`).
		- [0]: Property to be applied (e.g. `visibility`).
		- [1]: Default value
		- [2]: Value when on hover.
*/
$properties-map: (
	'visible': (
		'visibility',
		'hidden',
		'visible',
	),
	'visible-display': (
		'display',
		'none',
		'flex',
	),
	'visible-display-none': (
		'display',
		'flex',
		'none',
	),
	'invisible': (
		'visibility',
		'visible',
		'hidden',
	),
	'hide': (
		'visibility',
		'visible',
		'hidden',
	),
);

/*
	This is how it is structured:
		- key: Name of the class (e.g. `hover` -> `hover-parent`).
		- values: CSS selectors that will trigger the class (e.g. `focus` -> `:focus` and `:focus-within`).
*/
$selectors: (
	'hover': (
		'hover',
	),
	'focus': (
		'focus',
		'focus-within',
	),
);

/*
	This files generates classes like the following:

	```css
	.visible-on-hover {
		visibility: hidden;
	}

	.hover-parent:hover {
		.visible-on-hover {
			visibility: visible;
		}
	}

	.visible-on-focus {
		visibility: hidden;
	}

	.focus-parent:focus,
	.focus-parent:focus-within {
		.visible-on-focus {
			visibility: visible;
		}
	}
	```

	And the same for every other item in `$properties-map` and `$selectors`.
*/
@mixin generateVisibilityClasses($selector-class-name, $selectors-list) {
	@each $name, $value in $properties-map {
		.#{$name} {
			&-on-#{$selector-class-name} {
				#{list.nth($value, 1)}: string.unquote(list.nth($value, 2));

				@each $selector-name in $selectors-list {
					/*
						This example asumes that we are using `visible` and `hover`.

						This prevents nested `.hover-parent` from activating all the children.

						In other words: only the first parent will activate the children.

						Example:
						
						```html
						<div id="1" class="hover-parent">
							<div id="2" class="hover-parent">
								<div id="3" class="visible-on-hover"></div>
							</div>
							<div id="4" class="visible-on-hover"></div>
						</div>
						```

						- If we hover over `1`, only `4` will be visible.

						- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
					*/
					@at-root .#{$selector-class-name}-parent:#{$selector-name}
							.#{$selector-class-name}-parent:not(:#{$selector-name})
							& {
						#{list.nth($value, 1)}: string.unquote(list.nth($value, 2));
					}

					@at-root .#{$selector-class-name}-parent:#{$selector-name} & {
						#{list.nth($value, 1)}: string.unquote(list.nth($value, 3));
					}
				}
			}
		}
	}
}

/*
	This adds the required `@media` query for the hover (and not for the others).
*/
@each $selector-class-name, $selectors-list in $selectors {
	@if $selector-class-name == 'hover' {
		@media (hover: hover) {
			@include generateVisibilityClasses($selector-class-name, $selectors-list);
		}
	} @else {
		@include generateVisibilityClasses($selector-class-name, $selectors-list);
	}
}

@media not (hover: hover) {
	.invisible-on-mobile {
		visibility: hidden;
	}

	.hidden-on-mobile {
		display: none;
	}
}

.invisible {
	visibility: hidden;
}

.visible {
	visibility: visible;
}

.copy-button {
	&:not(:focus) {
		.visible-on-active {
			display: none !important;
		}
	}

	&:focus {
		.hidden-on-active {
			display: none !important;
		}
	}
}
.active {
	&.visible-on-active {
		visibility: visible;
	}
}
