.reach-toggler {
	position: relative;

	display: inline-block;

	color: var(--color-text-400);

	--__space_px: 1;
	--__space: calc((var(--__space_px) / 8) * 1em);

	--__indicator-size: calc(1em - (var(--__space)));

	border-radius: calc((0.5 * var(--__indicator-size)) + var(--__space));
	height: calc(var(--__indicator-size) + (2 * var(--__space)));
	width: calc((2 * var(--__indicator-size)) + (3 * var(--__space)));

	background-color: currentColor;

	&::after {
		content: "";
		position: absolute;
		top: 1px;
		left: 1px;
		width: calc(1em - 0px);
		height: calc(1em - 0px);
		border-radius: calc(0.7* var(--__indicator-size));
		background-color: var(--color-bg-190);
		transform: translateX(0px);
		transition: transform 300ms;
	}

	&.active {
		color: var(--color-primary);

		&::after {
			transform: translateX(calc(var(--__indicator-size) + var(--__space)));
		}
	}
}
