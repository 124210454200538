@use '../spacing/_spacing-directions' as directions;

$font-sizes-mobile: (
	h1: 24,
	xxl: 22,
	xl: 20,
	l: 18,
	m: 16,
	s: 14,
	xs: 12,
	xxs: 10,
	icon-s: 16,
	icon-small: 16,
	icon: 24,
	icon-l: 28,
	icon-2x: 48,
);

$font-sizes-desktop: (
	h1: 24,
	xxl: 20,
	xl: 18,
	l: 16,
	m: 14,
	s: 12,
	xs: 12,
	xxs: 10,
	icon-s: 16,
	icon-small: 16,
	icon: 22,
	icon-l: 24,
	icon-2x: 44,
);

:root {
	font-size: 16px;

	--font-line-height: 1.2;

	line-height: var(--font-line-height);

	@each $size, $value in $font-sizes-mobile {
		--fs-#{$size}: calc((#{$value} / 16) * 1rem);
		--font-size-#{$size}: calc((#{$value} / 16) * 1rem);
	}

	&.screen-desktop {
		font-size: 14px;
		@each $size, $value in $font-sizes-desktop {
			--fs-#{$size}: calc((#{$value} / 14) * 1rem);
			--font-size-#{$size}: calc((#{$value} / 14) * 1rem);
		}
		[class^='icon-'],
		[class*=' icon-'],
		[class^='iconff-'],
		[class*=' iconff-'],
		[class^='ns-icon-'],
		[class*=' ns-icon-'] {
			&.fs-m {
				font-size: var(--fs-l);
			}
		}
	}
}

body {
	font-size: var(--fs-m);
}

[class^='icon-'],
[class*=' icon-'],
[class^='iconff-'],
[class*=' iconff-'],
[class^='ns-icon-'],
[class*=' ns-icon-'] {
	font-size: var(--fs-icon);

	&.fs {
		@each $size, $value in $font-sizes-mobile {
			&-#{$size} {
				font-size: var(--fs-#{$size});
			}
		}

		&-icon {
			&-2x {
				font-size: calc(2 * var(--fs-icon));
			}
			&-l {
				font-size: var(--fs-icon-l);
			}
		}

		&-em {
			font-size: 1em;
		}
	}
}

.fs {
	@each $size, $value in $font-sizes-mobile {
		&-#{$size} {
			font-size: var(--fs-#{$size});
		}
	}

	&-sp {
		@for $i from 0 through 10 {
			@each $division, $division-value in directions.$spacing-multiplications {
				&-#{$i}#{$division} {
					font-size: calc((#{$division-value} + #{$i}) * var(--spacing));
				}
			}
		}
	}

	&-em {
		font-size: 1em;
	}
}

.ws,
.white-space {
	&-nw,
	&-nowrap,
	&-no-wrap {
		white-space: nowrap;
	}

	&-pl,
	&-pre-line {
		white-space: pre-line;
	}
}

.wb,
.word-break {
	&-bw,
	&-break-word {
		word-break: break-word;
	}

	&ba,
	&-break-all {
		word-break: break-all;
	}
}

.talign {
	&-start {
		text-align: start;
	}

	&-end {
		text-align: end;
	}

	&-l,
	&-left {
		text-align: left;
	}

	&-c,
	&-center {
		text-align: center;
	}

	&-r,
	&-right {
		text-align: right;
	}
}

.l-h,
.lh,
.line-height {
	&-regular {
		line-height: var(--font-line-height);
	}

	&-1,
	&-em {
		line-height: 1;
	}

	&-fs {
		@each $size, $value in $font-sizes-mobile {
			&-#{$size} {
				line-height: var(--fs-#{$size});
			}
		}
	}
}
