// This is legacy. Do not use if possible.

.settings-fixed-header {
	position: absolute;
	z-index: 1;
	top: var(--safe-area-inset-top);
}

.settings-scroll {
	position: absolute;
	top: calc(2rem + var(--fs-icon) + var(--safe-area-inset-top));
	bottom: var(--safe-area-inset-bottom);
	max-height: calc(100% - 2rem - var(--fs-icon));
	overflow: auto;
	width: 100%;
}
