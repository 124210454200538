@use 'sass:map';
@use 'sass:math';
@use 'sass:list';

$spacing-multiplications: (
	'': 0,
	'q': 0.25,
	// '\\.25': 0.25,
	'h': 0.5,
	// '\\.5': 0.5,
	't': 0.75,
	// '\\.75': 0.75,
);

$corners: (
	'top-right': 'tr',
	'bottom-right': 'br',
	'bottom-left': 'bl',
	'top-left': 'tl',
);

/*
	The first name should be `tr`, but since thinking that the
	first might be `tl`, I'm also adding that permutation as
	a safe measure.
*/
$all-corners: (
	'top-right': 'tr' 'trbr' 'trbrbl' 'trbrbltl' 'tltrbrbl' 'trbrtl' 'tltrbr' 'trbl' 'trbltl'
		'tltrbl' 'trtl' 'tltr',
	'bottom-right': 'trbr' 'trbrbl' 'trbrbltl' 'tltrbrbl' 'trbrtl' 'tltrbr' 'br' 'brbl' 'brbltl'
		'tlbrbl' 'brtl' 'tlbr',
	'bottom-left': 'trbrbl' 'trbrbltl' 'tltrbrbl' 'trbl' 'trbltl' 'tltrbl' 'brbl' 'brbltl' 'tlbrbl'
		'bl' 'bltl' 'tlbl',
	'top-left': 'trbrbltl' 'tltrbrbl' 'trbrtl' 'tltrbr' 'trbltl' 'tltrbl' 'trtl' 'tltr' 'brbltl'
		'tlbrbl' 'brtl' 'tlbr' 'bltl' 'tlbl' 'tl',
);

$sides: (
	'top': 't',
	'right': 'r',
	'bottom': 'b',
	'left': 'l',
);

$all-sides: (
	'top': 't' 'tr' 'trb' 'trbl' 'trl' 'tb' 'tbl' 'tl',
	'right': 'tr' 'trb' 'trbl' 'trl' 'r' 'rb' 'rbl' 'rl',
	'bottom': 'trb' 'trbl' 'tb' 'tbl' 'rb' 'rbl' 'b' 'bl',
	'left': 'trbl' 'trl' 'tbl' 'tl' 'rbl' 'rl' 'bl' 'l',
);
