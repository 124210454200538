ngx-extended-pdf-viewer {
	// for some reason the library makes everything `box-sizing: content-box !important;`
	div.zoom .border-box-wrapper,
	div.zoom .border-box-wrapper * {
		box-sizing: border-box !important;
	}

	button:focus,
	a:focus,
	input:focus,
	select:focus {
		border: none !important;
	}

	::selection {
		background-color: rgba(var(--color-primary-rgb), 0.2) !important;
	}

	.toolbar {
		border: none !important;
	}

	#sidebarContent {
		top: 0px !important;
        padding-left: 2px;
	}

	.toolbar {
		border-color: var(--color-line-230) !important;
	}

	#sidebarContainer {
		width: calc(100px + (2 * var(--spacing))) !important;
	}

	.html #thumbnailView {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		gap: calc(5.55 * var(--spacing));

		padding: 0 !important;
		padding-right: calc(4 * var(--spacing)) !important;

		width: calc(100% - (2 * var(--spacing))) !important;

		counter-reset: page;
        overflow: scroll !important;
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
        &::-webkit-scrollbar {
            display: none;
        }
	}
    

	#outerContainer.sidebarOpen #viewerContainer:not(.pdfPresentationMode) {
		left: calc(100px + (2*var(--spacing))) !important;
	}

	.html #thumbnailView a {
		border: none !important;
		outline: none;

		position: relative;

		counter-increment: page;

		position: relative;

		&::before {
			content: counter(page);

			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			padding-top: var(--spacing);

			font-weight: var(--fw-bold);
			font-size: var(--fs-xxs);
			color: var(--color-text-500);

			white-space: nowrap;
		}

		&:has(.thumbnail.selected)::before {
			color: var(--color-text-800);
		}
	}

	.html #thumbnailView .thumbnail {
		border-radius: calc(1.5 * var(--spacing));

		outline: none;

		margin: 0 !important;
		padding: 0 !important;
		border-width: 1px;

		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	#thumbnailView .thumbnail.selected {
		border-color: var(--color-primary) !important;
	}

	#loadingBar {
		background-color: transparent !important;
		border: none !important;
	}

	.annotationEditorLayer {
		pointer-events: none !important;
	}

	#viewerContainer {
		border-radius: calc(3 * var(--spacing)) !important;
	}

	.body {
		background-color: unset !important;
	}

	.pdfViewer .page {
		border-image: none !important;
	}

	#toolbarSidebar,
	#sidebarContent,
	#toolbarContainer,
	.findbar,
	.secondaryToolbar,
	.editorParamsToolbar {
		background-color: var(--color-bg-280) !important;
		color: var(--color-text-500) !important;
	}

	.html #scaleSelect,
	.html select,
	.html .toolbarField {
		background-color: var(--color-bg-160) !important;
		color: var(--color-text-500) !important;
		border-color: var(--color-line-230) !important;
		caret-color: var(--color-primary) !important;
	}

	.html .toolbarButton,
	.html .dropdownToolbarButton,
	.html .secondaryToolbarButton,
	.html .toolbarLabel {
		color: var(--color-text-500) !important;
	}
}
