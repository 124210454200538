// TODO: Use scss map

:root {
	--fw-thin: 200;
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-semi-bold: 600;
	--fw-bold: 700;
	--fw-black: 900;
}

.fw {
	&-t,
	&-thin,
	&-200 {
		font-weight: var(--fw-thin);
	}

	&-l,
	&-light,
	&-300 {
		font-weight: var(--fw-light);
	}

	&-r,
	&-regular,
	&-400 {
		font-weight: var(--fw-regular);
	}

	&-m,
	&-medium,
	&-500 {
		font-weight: var(--fw-medium);
	}

	&-sb,
	&-semi-bold,
	&-semibold,
	&-600 {
		font-weight: var(--fw-semi-bold);
	}

	&-b,
	&-bold,
	&-700 {
		font-weight: var(--fw-bold);
	}

	&-xb,
	&-bl,
	&-black,
	&-900 {
		font-weight: var(--fw-black);
	}
}
