@use '_spacing-directions' as directions;

/*
    `m` is for margin.
    
	It follows the same rules as `padding.scss`, but for margin.
*/
:root .m {
	@each $direction, $permutations in directions.$all-sides {
		@each $permutation in $permutations {
			&-safe-#{$permutation} {
				margin-#{$direction}: var(--safe-area-inset-#{$direction});
			}
		}
	}

	&-auto {
		margin: auto;

		@each $direction, $permutations in directions.$all-sides {
			@each $permutation in $permutations {
				&-#{$permutation} {
					margin-#{$direction}: auto;
				}
			}
		}
	}

	@for $i from 0 through 4 {
		@each $division, $division-value in directions.$spacing-multiplications {
			@each $direction, $permutations in directions.$all-sides {
				&-#{$i}#{$division} {
					margin-#{$direction}: calc((#{$i} + #{$division-value}) * var(--spacing));
				}

				@each $permutation in $permutations {
					&-#{$i}#{$division}-#{$permutation} {
						margin-#{$direction}: calc((#{$i} + #{$division-value}) * var(--spacing));
					}
				}
			}
		}
	}

	// this is to avoid classes that we don't use, so it's the same as above but without divisions
	@for $i from 5 through 10 {
		@each $direction, $permutations in directions.$all-sides {
			&-#{$i} {
				margin-#{$direction}: calc(#{$i} * var(--spacing));
			}

			@each $permutation in $permutations {
				&-#{$i}-#{$permutation} {
					margin-#{$direction}: calc(#{$i} * var(--spacing));
				}
			}
		}
	}
}
