// TODO: Use scss maps

// fl stands for "flex"
.fl,
.flex {
	display: flex;

	&-inline {
		display: inline-flex;
	}

	&[hidden] {
		display: none;
	}

	// fixed items are items that do not stretch nor shrink.

	&-fix,
	&-fixed {
		flex: 0 0 auto;
	}

	// adaptable items are items that can stretch and shrink.

	&-adapt,
	&-flexible,
	&-adaptable {
		flex: 1 1 auto;
	}

	&-w,
	&-wrap {
		flex-wrap: wrap;
	}

	&-gap {
		@for $i from 0 through 6 {
			&-#{$i} {
				gap: calc(#{$i} * var(--spacing));
			}

			&-#{$i}q {
				gap: calc((#{$i} + 0.25) * var(--spacing));
			}

			&-#{$i}h {
				gap: calc((#{$i} + 0.5) * var(--spacing));
			}

			&-#{$i}t {
				gap: calc((#{$i} + 0.75) * var(--spacing));
			}
		}
	}

	// dir stands for direction

	&-dir,
	&-dir {
		&-r,
		&-row {
			flex-direction: row;

			&-r,
			&-rev,
			&-reverse {
				flex-direction: row-reverse;
			}
		}

		&-c,
		&-col,
		&-column {
			flex-direction: column;

			&-r,
			&-rev,
			&-reverse {
				flex-direction: column-reverse;
			}
		}
	}

	// ai stands for "align-items".

	&-ai {
		&-start,
		&-fs {
			align-items: flex-start;
		}

		&-end,
		&-fe {
			align-items: flex-end;
		}

		&-center,
		&-c {
			align-items: center;
		}

		&-stretch,
		&-st {
			align-items: stretch;
		}
	}

	&-ac {
		&-start,
		&-fs {
			align-content: flex-start;
		}

		&-end,
		&-fe {
			align-content: flex-end;
		}

		&-center,
		&-c {
			align-content: center;
		}

		&-stretch,
		&-st {
			align-content: stretch;
		}
	}

	&-aself {
		&-start,
		&-fs {
			align-self: flex-start;
		}

		&-end,
		&-fe {
			align-self: flex-end;
		}

		&-center,
		&-c {
			align-self: center;
		}

		&-stretch,
		&-st {
			align-self: stretch;
		}
	}

	// jc stands for "justify-content"

	&-jc {
		&-start,
		&-fs {
			justify-content: flex-start;
		}

		&-end,
		&-fe {
			justify-content: flex-end;
		}

		&-center,
		&-c {
			justify-content: center;
		}

		&-stretch,
		&-st {
			justify-content: stretch;
		}

		&-spa,
		&-around {
			justify-content: space-around;
		}

		&-spb,
		&-between {
			justify-content: space-between;
		}

		&-spe,
		&-even,
		&-evenly {
			justify-content: space-evenly;
		}
	}

	&-jself {
		&-start,
		&-fs {
			justify-self: flex-start;
		}

		&-end,
		&-fe {
			justify-self: flex-end;
		}

		&-center,
		&-c {
			justify-self: center;
		}

		&-stretch,
		&-st {
			justify-self: stretch;
		}

		&-spa,
		&-around {
			justify-self: space-around;
		}

		&-spb,
		&-between {
			justify-self: space-between;
		}

		&-spe,
		&-even,
		&-evenly {
			justify-self: space-evenly;
		}
	}
}
