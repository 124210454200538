@use 'common-colors' as common;

:root {
	--color-highlight-text-rgb: 48, 107, 159;
	--color-highlight-text: rgb(var(--color-highlight-text-rgb));
	--color-highlight-background: rgba(var(--color-primary-rgb), 0.25);

	@include common.setContentWhenDarkTheme {
		--color-highlight-text-rgb: var(--color-primary-rgb);
		--color-highlight-text: rgb(var(--color-highlight-text-rgb));
	}
}
