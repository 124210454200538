.icon-arrow-right::before {
	display: inline-block;
}

.icon-arrow-right.arrow-up::before,
.icon-arrow-right.arrow-top::before {
	transform: rotate(-90deg);
}

.icon-arrow-right.arrow-right::before {
	transform: rotate(0deg);
}

.icon-arrow-right.arrow-down::before,
.icon-arrow-right.arrow-bottom::before {
	transform: rotate(90deg);
}

.icon-arrow-right.arrow-left::before {
	transform: rotate(180deg);
}

.icon-arrow::before,
.icon-chevron::before,
.icon-double-chevron::before,
.icon-chevron-big::before,
.icon-chevron-large::before {
	transition: transform 0.3s;
}

.icon-arrow.arrow-up::before,
.icon-arrow.arrow-top::before,
.icon-chevron.chevron-up::before,
.icon-chevron-big.chevron-up::before,
.icon-chevron-large.chevron-up::before,
.icon-double-chevron.chevron-up::before,
.icon-chevron.chevron-top::before,
.icon-chevron-big.chevron-top::before,
.icon-chevron-large.chevron-top::before,
.icon-double-chevron.chevron-top::before {
	display: inline-block;
	transform: rotate(0deg);
}

.icon-arrow.arrow-left::before,
.icon-chevron.chevron-left::before,
.icon-double-chevron.chevron-left::before,
.icon-chevron-big.chevron-left::before,
.icon-chevron-large.chevron-left::before {
	display: inline-block;
	transform: rotate(-90deg);
}

.icon-arrow.arrow-right::before,
.icon-chevron.chevron-right::before,
.icon-double-chevron.chevron-right::before,
.icon-chevron-big.chevron-right::before,
.icon-chevron-large.chevron-right::before {
	display: inline-block;
	transform: rotate(90deg);
}

.icon-arrow.arrow-bottom::before,
.icon-arrow.arrow-down::before,
.icon-chevron.chevron-bottom::before,
.icon-chevron-big.chevron-bottom::before,
.icon-chevron-large.chevron-bottom::before,
.icon-double-chevron.chevron-bottom::before,
.icon-chevron.chevron-down::before,
.icon-chevron-big.chevron-down::before,
.icon-chevron-large.chevron-down::before,
.icon-double-chevron.chevron-down::before {
	display: inline-block;
	transform: rotate(180deg);
}

.icon-loading-bubbles::before {
	display: inline-block;
	animation-name: rotate;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	background-image: conic-gradient(
		from 0deg at center,
		rgba(var(--color-primary-rgb), 0),
		rgba(var(--color-primary-rgb), 1)
	);
	background-clip: text;
	color: transparent;
}

.node-type-color {
	color: var(--color-node);
}

.node-type-bg {
	border-radius: 50%;
	background-color: var(--color-node-bg);
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.icon-trending_up.reversed {
	transform: scale(1, -1);
}
