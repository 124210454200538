@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';
@use 'common-colors' as common;

$map: (
	'0': (
		0,
		0,
		0,
		0,
	),
	// transparent
	'1':
		(
			45,
			112,
			192,
		),
	// #2D70C0
	'2':
		(
			2,
			162,
			204,
		),
	// #02A2CC
	'3':
		(
			0,
			191,
			211,
		),
	// #00BFD3
	'4':
		(
			88,
			193,
			68,
		),
	// #57C042
	'5':
		(
			156,
			200,
			16,
		),
	// #9CC810
	'6':
		(
			209,
			202,
			0,
		),
	// #D1CA00
	'7':
		(
			252,
			198,
			0,
		),
	// #FCC600
	'8':
		(
			245,
			143,
			0,
		),
	// #F58F00
	'9':
		(
			241,
			89,
			42,
		),
	// #F1592A
	'10':
		(
			231,
			36,
			55,
		),
	// #E72437
);

$keys: map.keys($map);

@function cssVarNameGenerator($name) {
	@return '--color-imp-bg-#{$name}-rgb';
}

:root {
	--color-imp-bg-opacity: 0.3;

	@include common.setCssVariablesFromValuesMap($map, meta.get-function('cssVarNameGenerator'));
}

.imp,
.importance {
	@each $name in $keys {
		&-#{$name} {
			@if $name == '0' {
				--importance-color-rgb: var(--color-text-400-rgb);
				--importance-color: rgba(var(--color-text-400-rgb), 1);
			} @else {
				--importance-color-rgb: var(#{string.unquote(cssVarNameGenerator($name))});
				--importance-color: rgba(var(--importance-color-rgb), 1);
				--importance-color-bg: rgba(
					var(--importance-color-rgb),
					var(--color-imp-bg-opacity)
				);
			}
		}
	}
}

:root {
	.bg-importance {
		background-color: var(--importance-color-bg);

		@for $i from 0 through 20 {
			&-#{5 * $i} {
				background-color: rgba(var(--importance-color-rgb), #{0.05 * $i});
			}
		}

		&-on-hover {
			&:hover {
				background-color: var(--importance-color-bg);
			}
		}
	}

	.fc-importance {
		color: var(--importance-color);

		&-as-bg {
			background-color: var(--importance-color);
		}
	}
}
