@use 'sass:map';
@use '../../../ng-common-libraries/styles/colors/font-colors' as font-colors;

$tooltip-bg-color: map.get(font-colors.$map, 'light', '800');

:root {
	--color-bg-tooltip-rgb: #{$tooltip-bg-color};
}

.bg-tooltip {
	background-color: rgb(var(--color-bg-tooltip-rgb));
}

.fill-on-hover {
	@for $i from 0 through 20 {
		&-#{$i * 5} {
			&:hover {
				fill-opacity: $i * 0.05;
			}
		}
	}
}

.code-container {
	background-color: var(--code-code-bg);
	color: var(--code-code-text);
}
