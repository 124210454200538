[class^='icon-'],
[class*=' icon-'] {
	position: relative;

	font-family: inherit !important;

	&[hidden] {
		display: none;
	}

	&::before {
		display: flex;

		font-family: 'icomoon' !important;

		width: 1em;
		height: 1em;
	}
}

i {
	display: flex;

	width: 1em;
	height: 1em;
}

[class^='icon-'],
[class*=' icon-'],
[class^='iconff-'],
[class*=' iconff-'],
[class^='ns-icon-'],
[class*=' ns-icon-'] {
	&[hidden] {
		display: none;
	}
}
