@use '../spacing/_spacing-directions' as directions;

$positions: (
	'top': 't',
	'right': 'r',
	'bottom': 'b',
	'left': 'l',
);

:root {
	.pos {
		&-rel,
		&-relative {
			position: relative;
		}
	
		&-abs,
		&-absolute {
			position: absolute;
		}
	
		&-f,
		&-fixed {
			position: fixed;
		}
	
		&-s,
		&-sticky {
			position: sticky;
		}
	
		@each $name, $short in $positions {
			&-#{$short},
			&-#{$name} {
				&0h,
				&-0h {
					#{$name}: var(--spacing);
				}
	
				&-50 {
					#{$name}: 50%;
				}
	
				&-100 {
					#{$name}: 100%;
				}
	
				&,
				&-sp {
					@for $i from 0 through 4 {
						@each $division, $division-value in directions.$spacing-multiplications {
							&-#{$i}#{$division} {
								#{$name}: calc((#{$i} + #{$division-value}) * var(--spacing));
							}
						}
					}
				}
			}
		}
	}
}
