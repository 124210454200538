@use 'sass:map';
@use 'sass:meta';
@use 'common-colors' as common;

$color-primary-500-rgb: (72, 161, 238); // #48A1EE
$color-primary-700-rgb: (48, 107, 159); // #306B9F
$color-warn-500-rgb-light: (208, 2, 27); // D0021B
$color-warn-700-rgb-light: (208, 2, 27); // A40013
$color-warn-500-rgb-dark: (225, 82, 65); // E15241
$color-warn-700-rgb-dark: (208, 2, 27); // D0021B

$map: (
	'light': (
		'primary': $color-primary-500-rgb,
		'primary-dark': $color-primary-700-rgb,
		'primary-500': $color-primary-500-rgb,
		'primary-700': $color-primary-700-rgb,
		'warn': $color-warn-500-rgb-light,
		'warn-dark': $color-warn-700-rgb-light,
		'warn-500': $color-warn-500-rgb-light,
		'warn-700': $color-warn-700-rgb-light,
	),
	'dark': (
		'primary': $color-primary-500-rgb,
		'primary-dark': $color-primary-700-rgb,
		'primary-500': $color-primary-500-rgb,
		'primary-700': $color-primary-700-rgb,
		'warn': $color-warn-500-rgb-dark,
		'warn-dark': $color-warn-700-rgb-dark,
		'warn-500': $color-warn-500-rgb-dark,
		'warn-700': $color-warn-700-rgb-dark,
	),
);

$keys: map.keys(map.get($map, 'light'));

@function cssVarNameGenerator($name) {
	@return '--color-#{$name}-rgb';
}

@function cssVarNameGeneratorAuxiliar($name) {
	@return '--color-theme-#{$name}-rgb';
}

:root {
	@include common.setCssVariablesFromLightAndDarkMap(
		$map,
		meta.get-function('cssVarNameGenerator')
	);

	@include common.setCssVariablesFromLightAndDarkMap(
		$map,
		meta.get-function('cssVarNameGeneratorAuxiliar')
	);
}
