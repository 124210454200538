// This is legacy. DO not use if possible.

.card-separator {
	--__gap-size: var(--__gap, calc((2 * var(--spacing)) + var(--fs-icon) + (2 * var(--spacing))));
	margin-left: var(--__gap-size);
	width: calc(100% - var(--__gap-size));
	height: 1px;
	background-color: var(--color-line-230);

	&.new-colors {
		background-color: var(--color-line-230);
	}

	&-no-icon {
		width: calc(100% - 1rem);
		margin-left: 1rem;
	}

	&.force {
		background-color: var(--color-line-230) !important;
	}
}

.reach-card {
	&:last-of-type {
		.card-separator {
			display: none;
			background-color: transparent;
		}
	}
}

.reach-card-new {
	&:last-of-type {
		> .card-separator {
			display: none;
			background-color: transparent;
		}
	}
}

.separator {
    width: 100%;
    height: 1px;
    background-color: var(--color-line-230);
}
