:root {
	--mdc-theme-error: var(--color-theme-warn);
	--mat-sidenav-content-background-color: rgb(var(--color-bg-180-rgb));
	--mat-sidenav-container-background-color: rgb(var(--color-bg-180-rgb));
}

.mat-mdc-checkbox.mat-primary {
	--mdc-checkbox-selected-checkmark-color: var(--color-text-white);
	--mdc-checkbox-unselected-icon-color: var(--color-text-400);
	--mdc-checkbox-unselected-focus-icon-color: var(--color-text-400);
	--mdc-checkbox-unselected-hover-icon-color: var(--color-text-400);
}

.mat-unthemed {
	--mdc-text-button-label-text-color: var(--color-text-800) !important;
}

.mat-datepicker-content,
.mat-mdc-datepicker-content {
	background-color: var(--color-bg-290) !important;
}

.mat-calendar-body-selected,
.mat-mdc-calendar-body-selected {
	background-color: var(--color-primary) !important;
}

.mat-calendar-table-header,
.mat-mdc-calendar-table-header,
.mat-calendar-body-label,
.mat-mdc-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-mdc-calendar-body-cell-content {
	color: var(--color-text-800) !important;
}

.mat-calendar-body-cell-content.mat-calendar-body-selected {
	background-color: var(--color-primary) !important;
	color: var(--color-text-white) !important;
}

.mat-calendar-table-header span {
	color: var(--color-text-800) !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
	color: var(--color-primary);
}

.mat-calendar-arrow {
	fill: var(--color-primary);
}

.mat-calendar-table-header-divider::after {
	background: var(--color-line-230);
}

.mat-mdc-icon-button[disabled][disabled] {
	--mdc-icon-button-disabled-icon-color: var(--color-text-400) !important;
}

.mat-form-field.mat-mdc-focused,
.mat-mdc-form-field.mat-mdc-focused {
	.mat-form-field-ripple,
	.mat-mdc-form-field-ripple {
		background-color: var(--color-primary);
	}
}

.mat-checkbox,
.mat-mdc-checkbox {
	> label {
		> .mat-checkbox-label,
		> .mat-mdc-checkbox-label {
			white-space: normal;
		}
	}
}

.mat-checkbox-background,
.mat-mdc-checkbox-background,
.mat-checkbox-frame,
.mat-mdc-checkbox-frame {
	border-radius: 3px !important;
}

.mat-checkbox-checkmark,
.mat-mdc-checkbox-checkmark {
	fill: transparent !important;
}

.mat-checkbox-checkmark-path,
.mat-mdc-checkbox-checkmark-path {
	stroke: var(--color-text-white) !important;
	stroke-width: 3px !important;
	stroke-linecap: round !important;
	stroke-linejoin: round !important;
}

.mat-form-field.mat-mdc-form-field-invalid,
.mat-mdc-form-field.mat-mdc-form-field-invalid {
	.mat-form-field-label,
	.mat-mdc-form-field-label {
		color: var(--color-warn);
	}
}

.mat-form-field,
.mat-mdc-form-field {
	.mat-form-field-subscript-wrapper,
	.mat-mdc-form-field-underline,
	.mat-form-field-subscript-wrapper,
	.mat-mdc-form-field-underline {
		position: initial;
	}
}

.mat-form-field-required-marker,
.mat-mdc-form-field-required-marker {
	&::after {
		display: none !important;
	}
}

// INPUTS

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
	color: var(--color-text-800);
}

// remove background
.mat-mdc-text-field-wrapper,
.mat-mdc-form-field-flex,
.mat-mdc-form-field-focus-overlay {
	background-color: transparent !important;
}

// remove left padding
.mat-mdc-form-field-error-wrapper,
.mat-mdc-text-field-wrapper {
	padding-left: 0px !important;
}

// change label position
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: unset !important;
	bottom: 0px;
}
.mdc-text-field--filled .mdc-floating-label--float-above {
	transform: translateY(-1.5em) scale(0.75) !important;
}

// color
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--color-text-400) !important;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--color-primary) !important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--color-warn) !important;
}

.mat-mdc-form-field-icon-suffix {
	padding-top: 24px !important;
}

// border
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
	border-bottom-color: var(--color-line-230);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
	border-bottom-color: var(--color-warn) !important;
}
.mdc-text-field--filled .mdc-line-ripple::after {
	border-bottom-color: var(--color-primary) !important;
}

// CHECKBOX
.mat-mdc-checkbox
	.mdc-checkbox
	.mdc-checkbox__native-control:enabled
	~ .mdc-checkbox__background
	.mdc-checkbox__checkmark {
	color: var(--color-text-white);
}

// TOOLTIP
.reach-tooltip {
	div {
		background-color: var(--light-color-text-800);
		color: var(--color-text-white);
	}
}
