@use 'sass:map';
@use 'sass:meta';
@use 'common-colors' as common;
@use 'theme-colors' as theme;
@use 'importance-colors' as importance;
@use '../spacing/spacing-directions' as directions;

/* 
	This generates all the permutations of the border colors, resulting in:
    'light': (
        '230': <light value for color '2'>,
        (...)
    ),
    'dark': (
        '230': <dark value for color '3'>,
        (...)
    ),
*/
$map: common.getAllLightAndDarkMapPermutations(
	(
		'light': (
			'2': (
				226,
				226,
				226,
			),
			// #E2E2E2
			'3':
				(
					185,
					184,
					188,
				),
			// #B9B8BC
		),
		'dark': (
			'3': (
				78,
				79,
				85,
			),
			// #4E4F55
			'5':
				(
					45,
					46,
					49,
				),
			// #2D2E31
		),
	)
);

$keys: map.keys(map.get($map, 'light'));

@function cssVarNameGenerator($name) {
	@return '--color-line-#{$name}-rgb';
}

:root {
	@include common.setCssVariablesFromLightAndDarkMap(
		$map,
		meta.get-function('cssVarNameGenerator')
	);
}

@mixin setBorderColor($name, $cssVarName, $includeOtherProperties: false) {
	&-#{$name} {
		@at-root :root & {
			border: 1px solid rgb(var(#{$cssVarName}));

			// @include common.addAlphaValues($cssVarName, border-color);

			@each $direction, $permutations in directions.$all-sides {
				@each $permutation in $permutations {
					&-#{$permutation} {
						border-#{$direction}: 1px solid rgb(var(#{$cssVarName}));
					}
				}
			}

			@if $includeOtherProperties {
				&-as-fc {
					color: rgb(var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, color);
				}

				&-as-bg {
					background-color: rgb(var(#{$cssVarName}));
					// @include common.addAlphaValues($cssVarName, background-color);
				}
			}
		}

		@include common.addHoverClasses(rgb(var(#{$cssVarName})), border-color);
	}
}

:root {
	.bordered {
		border: 1px solid rgb(var(#{cssVarNameGenerator('250')}));
	}
}

.line,
.border {
	&-transparent {
		@at-root :root & {
			border-color: transparent;
		}
	}

	@each $name in $keys {
		@include setBorderColor(
			$name: $name,
			$cssVarName: cssVarNameGenerator($name),
			$includeOtherProperties: true
		);
	}

	&,
	&-theme {
		@each $name in theme.$keys {
			@include setBorderColor(
				$name: $name,
				$cssVarName: theme.cssVarNameGenerator($name),
				$includeOtherProperties: false
			);
		}
	}

	// &-importance,
	// &-imp {
	// 	@each $name in importance.$keys {
	// 		@include setBorderColor(
	// 			$name: $name,
	// 			$cssVarName: importance.cssVarNameGenerator($name),
	// 			$includeOtherProperties: false
	// 		);
	// 	}
	// }

	&-primary-on {
		&-focus {
			&:focus {
				border: 1px solid rgb(var(--color-primary-rgb));
			}
		}
		&-focus-within {
			&:focus-within {
				border: 1px solid rgb(var(--color-primary-rgb));
			}
		}
	}
}
