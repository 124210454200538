//////////////
// https://github.com/Lazzzer00/Best-CSS-Reset-2024/blob/main/css_reset.css
//////////////

*,
*::before,
*::after {
	box-sizing: border-box; /* Set the sizing of an element to include it's border */
}

* {
	margin: 0; /* Set the default margin to 0 */
	padding: 0; /* Set the default padding to 0 */

	word-break: break-all;
	word-break: break-word;
}

html:focus-within {
	scroll-behavior: smooth; /* Make the scrolling inside of any scrollable element smooth */
}

a:not([class]) {
	text-decoration-skip-ink: auto; /* Makes link undelines look better */
}

/* Turns off animation for people who don't want to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
		transition: none;
	}
}
