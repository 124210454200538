.section-separator {
    padding: calc(0.75 * var(--spacing)) calc(2 * var(--spacing));

    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(2 * var(--spacing));

    background-color: var(--color-bg-280);
    color: var(--color-text-400);

    border-radius: var(--spacing);

    font-size: var(--fs-m);
    line-height: 1em;
}
