// [data-placeholder] {
// 	outline: none !important;

// 	caret-color: var(--color-theme-primary);

// 	&:empty {
// 		&::before {
// 			position: absolute;
// 			pointer-events: none;
// 			content: attr(data-placeholder);
// 			color: var(--color-text-alt);
// 		}
// 	}
// }

textarea,
input {
	background-color: transparent;
}

.reach-input-element,
textarea,
input {
	--_border-color: rgb(var(--color-line-250-rgb));
	--_shadow-color-rgb: var(--color-theme-primary-rgb);

	border: 1px solid var(--_border-color, transparent);
	border-radius: var(--spacing);

	padding: calc(1.5 * var(--spacing)) calc(2 * var(--spacing));

	caret-color: var(--color-theme-primary);
	color: var(--color-text-800);

	&[disabled] {
		color: var(--color-text-500);
	}

	&::placeholder {
		color: var(--color-text-400);
		opacity: 1;
	}

	&.without-border {
		border: none;
	}

	&:enabled:not(.without-focus) {
		&:hover {
			--_border-color: rgb(var(--_shadow-color-rgb));
		}
	}
}

.reach-input-element-wrapper {
	textarea,
	input {
		padding: 0px;
		border-radius: 0px;
		border: none;
		box-shadow: none !important;
	}
}

.reach-input-element:focus-within,
textarea:focus,
input:focus {
	outline: none;
	-webkit-box-shadow: none;
}

.reach-input-element:not(.without-focus):focus-within,
textarea:not(.without-focus):focus,
input:not(.without-focus):focus {
	border-color: rgb(var(--_shadow-color-rgb));
	// box-shadow: 0 0 0 0.2rem rgba(var(--_shadow-color-rgb), 0.25);
}

::placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder {
	color: var(--color-text-400);
}

.input-wrapper {
	&.with-errors {
		.reach-input-element,
		textarea,
		input {
			--_border-color: var(--color-theme-warn);
			--_shadow-color-rgb: var(--color-theme-warn-rgb);
		}
	}
}
